@font-face {
	font-family: 'GreatVibes';
	src: url('../../public/GreatVibes-Regular.ttf') format('ttf');
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../public/Montserrat-VariableFont_wght.ttf') format('ttf');
}

h2 {
  font-family: GreatVibes;
  font-size: large;
}

h1,p,a {
  text-decoration: none;
}

@media screen and (min-width:640px) {
  .contentContainer {
    margin: 0 6.5% 0 6.5%;
    justify-content: center;
  }

  nav {
    padding: 0 6.5% 0 6.5%;
  }
}
@media screen and (max-width:640px) {
  .contentContainer {
    margin: 0 20px 0 20px;
  }
  
  nav {
    padding: 0 20px 0 20px;
  }

  .mobileText{
    font-size: 11px;
  }
  
  }

img {
  max-width: 1120px;
  max-height: 1200px;
}

.content {
  min-height: calc(100vh - 80px - 56px);
}

.menuButton:hover{ 
  -webkit-transform: scale(1.3);
 }

 .locationCardBtn:hover{ 
  transition-duration: 200ms;
  -webkit-transform: scale(1.05);
 }
 
.carousel {
  max-height: 400px;
}
.slider {
  max-height: 400px;
}

.locationCard {
  width: 420px;
  height: 500px;  
}

.font-Montserrat {
  font-family: Montserrat;
}

.NavMenutxtStyle {
  font-family: Montserrat;
  font-size: 24px;
  color: black;
}
